/**
 * @file A simple implementation of an infinite scroll element
 * @author Alwyn Tan
 */

import { UseInfiniteQueryResult } from '@tanstack/react-query'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  overflow: auto;
`

type Props = {
  children: (item: any) => React.ReactNode
  endOfListComponent?: React.ReactNode
  loadingComponent?: React.ReactNode
  infiniteQueryHook: () => UseInfiniteQueryResult
  infiniteQueryHookParams?: []
  style?: React.CSSProperties
}

const InfiniteScroll = ({
  children,
  loadingComponent = <p>Loading</p>,
  endOfListComponent = null,
  infiniteQueryHook,
  infiniteQueryHookParams = [],
  style = {},
}: Props) => {
  const infiniteTriggerRef = useRef(null)

  // note: query needs to transform the data object to an array with the select option
  const { fetchNextPage, data, hasNextPage, isFetchingNextPage, isLoading } =
    infiniteQueryHook(...infiniteQueryHookParams)

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  return (
    <Container style={style}>
      {data &&
        data?.map((item: any, index: number) => (
          <React.Fragment key={item?.id || index}>
            {children(item)}
          </React.Fragment>
        ))}
      {(isLoading || isFetchingNextPage) && loadingComponent}
      {!hasNextPage && endOfListComponent}
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

export default InfiniteScroll
