/**
 * @file Get affiliate links
 * @author Alwyn Tan
 */

import { affiliateLinkKeys } from '#/query-key-factory'
import api from '#/utils/api'
import { useInfiniteQuery } from '@tanstack/react-query'

const getAffiliateLinks = async pageParam =>
  api.get(`affiliate`, { searchParams: { ...pageParam } }).json()

const useGetAffiliateLinks = () =>
  useInfiniteQuery(
    affiliateLinkKeys.list(),
    async ({ pageParam }) => getAffiliateLinks(pageParam),
    {
      select: data =>
        data?.pages?.reduce(
          (acc, curr) => [...acc, ...curr.affiliateLinks],
          []
        ),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevAffiliateLink =
            prevPage.affiliateLinks[(prevPage?.affiliateLinks.length || 0) - 1]
          return {
            prevID: prevAffiliateLink?.id,
            prevCreatedAt: prevAffiliateLink?.createdAt,
          }
        }
        return undefined
      },
    }
  )

export default useGetAffiliateLinks
