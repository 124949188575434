/**
 * @file Gets the dealClaims for the current user
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from '@tanstack/react-query'
import api from '#/utils/api'
import { dealClaimKeys } from '#/query-key-factory'

const getDealClaims = async pageParam =>
  api
    .get(`deal/claims`, {
      searchParams: { ...pageParam, populateDealAndDrop: true },
    })
    .json()

const useGetDealClaims = () =>
  useInfiniteQuery(
    dealClaimKeys.list(),
    async ({ pageParam }) => getDealClaims(pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.dealClaims], []),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevDealClaim =
            prevPage.dealClaims[(prevPage?.dealClaims.length || 0) - 1]
          return {
            prevID: prevDealClaim?.id,
            prevCreatedAt: prevDealClaim?.createdAt,
          }
        }
        return undefined
      },
    }
  )

export default useGetDealClaims
