/**
 * @file Row item used in visited list
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import DropCard from '#/components/molecules/DropCard'
import Text from '#/components/atoms/Text'
import dayjs from 'dayjs'
import Button from '#/components/molecules/Button'
import { DEAL_TYPES } from '#/constants/deal'
import { navigate } from 'gatsby'

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 350px;
  padding-bottom: 25px;
`

const InfoContainer = styled.div`
  flex: 1;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

type Props = {
  dealClaim: DealClaim
}

const SavedListItem = ({ dealClaim }: Props) => {
  const renderDealType = () => {
    switch (dealClaim?.dealID?.type) {
      case DEAL_TYPES.FREE_DRINK:
        return 'Free Drink'
      case DEAL_TYPES.FREE_DESSERT:
        return 'Free Dessert'
      default:
        return 'Free Item'
    }
  }

  const handleUseClick = () => {
    navigate(`/${dealClaim.affiliateLinkShortID}`)
  }

  // todo: patch dealclaim populated fields
  return (
    <Container>
      {dealClaim?.dealID?.dropID && (
        <DropCard
          style={{ height: 200, width: 130 }}
          drop={dealClaim?.dealID?.dropID}
        />
      )}
      <InfoContainer>
        <Text type="h5" style={{ paddingBottom: 8 }}>
          {renderDealType()}
        </Text>
        <Text type="p2" style={{ opacity: 0.4 }}>
          Claimed on:{' '}
          {dealClaim.createdAt &&
            dayjs(dealClaim.createdAt).tz().format('MMM D, YYYY')}
        </Text>
        <Text type="p2" style={{ opacity: 0.4 }}>
          Claimed from: {dealClaim?.affiliateLink?.createdBy?.name}
        </Text>
        <Button
          style={{ marginTop: 20 }}
          onClick={handleUseClick}
          disabled={dealClaim.used}
        >
          {dealClaim.used ? 'Used' : 'Use Now'}
        </Button>
      </InfoContainer>
    </Container>
  )
}

export default SavedListItem
