/**
 * @file
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Text from '#/components/atoms/Text'
import { OffBlack } from '#/constants/colors'
import TabNav from '#/components/molecules/TabNav'
import VisitedList from '#/components/organisms/profile/VisitedList'
import SavedList from '#/components/organisms/profile/SavedList'
import { useAuth } from '#/contexts/global-context'
import useCurrentUser from '#/hooks/query/useCurrentUser'
import Page from '#/components/templates/Page'

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${OffBlack};
`

// todo: cleanup types for tabnav
const MePage = () => {
  const { data: currentUser, isFetched } = useCurrentUser()
  const { presentAuthModal } = useAuth()

  useEffect(() => {
    if (isFetched && !currentUser)
      presentAuthModal(() => {}, {
        backdropDisabled: true,
      })
  }, [currentUser, isFetched, presentAuthModal])

  useEffect(() => {
    window.analytics.page()
  }, [])

  if (!currentUser) return null

  return (
    <Page
      title="Disco"
      renderTitle={() => <Text type="h1">DISCO</Text>}
      hideProfile
    >
      <Container
        initial={{ top: '-100vh' }}
        animate={{ top: 0 }}
        exit={{ top: '-100vh' }}
      >
        <TabNav>
          <SavedList title="Saved" />
          <VisitedList title="Visited" />
        </TabNav>
      </Container>
    </Page>
  )
}

export default MePage
