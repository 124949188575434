/**
 * @file Use get drop query
 * @author Alwyn Tan
 */

import { useQuery } from '@tanstack/react-query'
import { dropKeys } from '#/query-key-factory'
import api from '#/utils/api'

type DropResponseJSON = {
  drop: Drop
}

const getDrop = async (id: string) =>
  api.get(`drop/${id}`).json<DropResponseJSON>()

const useGetDrop = (id: string) =>
  useQuery(dropKeys.detail(id), async () => getDrop(id), {
    enabled: !!id,
    select: data => data.drop,
  })

export default useGetDrop
