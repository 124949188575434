/**
 * @file Visited List (scanned QR Places)
 * @author Alwyn Tan
 */

import InfiniteScroll from '#/components/molecules/InfiniteScroll'
import useGetDealClaims from '#/hooks/query/deal/useGetDealClaims'
import React from 'react'
import styled from 'styled-components'
import SavedListItem from './SavedListItem'

const Container = styled.div`
  > * {
    margin-bottom: 25px;
  }
`

const SavedList = () => (
  <Container>
    <InfiniteScroll infiniteQueryHook={useGetDealClaims}>
      {dealClaim => <SavedListItem dealClaim={dealClaim} />}
    </InfiniteScroll>
  </Container>
)

export default SavedList
