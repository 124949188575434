/**
 * @file Visited List (scanned QR Places)
 * @author Alwyn Tan
 */

import InfiniteScroll from '#/components/molecules/InfiniteScroll'
import useGetAffiliateLinks from '#/hooks/query/affiliateLink/useGetAffiliateLinks'
import React from 'react'
import styled from 'styled-components'
import VisitedListItem from './VisitedListItem'

const Container = styled.div`
  > * {
    margin-bottom: 25px;
  }
`

const VisitedList = () => (
  <Container>
    <InfiniteScroll infiniteQueryHook={useGetAffiliateLinks}>
      {affiliateLink => <VisitedListItem affiliateLink={affiliateLink} />}
    </InfiniteScroll>
  </Container>
)

export default VisitedList
