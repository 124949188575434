/**
 * @file Drop Card
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Text from '../atoms/Text'

const Container = styled.div`
  height: 200px;
  width: 130px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 38%),
    ${({ src }: { src: string }) => `url(${src})`};
  background-size: cover;
  object-fit: contain;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const Info = styled.div`
  left: 8px;
  bottom: 8px;
  position: absolute;
`

type Props = {
  drop: Drop
  style: React.CSSProperties
}

const DropCard = ({ drop, style }: Props) => (
  <Container src={drop.thumbnail} style={style}>
    <Info>
      <Text type="h5">{drop.title}</Text>
      <Text type="p2" style={{ paddingTop: 3 }}>
        {drop.location}
      </Text>
    </Info>
  </Container>
)

export default DropCard
