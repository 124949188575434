/**
 * @file Row item used in visited list
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import DropCard from '#/components/molecules/DropCard'
import Text from '#/components/atoms/Text'
import { Link as LinkIcon } from 'react-feather'
import dayjs from 'dayjs'
import useGetDrop from '#/hooks/query/drop/useGetDrop'
import { navigate } from 'gatsby'

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 350px;
  padding-bottom: 25px;
`

const InfoContainer = styled.div`
  flex: 1;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > * {
    padding: 4px 0;
  }
`

const LinkContainer = styled.div`
  padding: 4px 10px;
  border-radius: 5px;
  display: flex;
  background-color: #ffffff1a;
  align-items: center;
  cursor: pointer;
`

type Props = {
  affiliateLink: AffiliateLink
}

const VisitedListItem = ({ affiliateLink }: Props) => {
  // these are always drops right now, so can assume
  // todo: patch this shitty implementation
  const { data: drop } = useGetDrop(affiliateLink?.data?.id)

  const handleItemClick = () => {
    navigate(`/${affiliateLink.shortID}`)
  }

  return (
    <Container onClick={handleItemClick}>
      {drop && <DropCard style={{ height: 200, width: 130 }} drop={drop} />}
      <InfoContainer>
        <LinkContainer>
          <Text type="h5">{affiliateLink.shortID}</Text>
          <LinkIcon color="white" size={16} style={{ marginLeft: 6 }} />
        </LinkContainer>
        <Text type="p1">👀 {affiliateLink.resolutions} views</Text>
        <Text type="p2" style={{ opacity: 0.4 }}>
          First visit:{' '}
          {affiliateLink.createdAt &&
            dayjs(affiliateLink.createdAt).tz().format('MMM D, YYYY')}
        </Text>
      </InfoContainer>
    </Container>
  )
}

export default VisitedListItem
