/**
 * @file Deal constants
 * @author Alwyn Tan
 */

// eslint-disable-next-line import/prefer-default-export
export const DEAL_TYPES = {
  FREE_DRINK: 'freedrink',
  FREE_DESSERT: 'freedessert',
}
