/**
 * @file Implementation of a swipable tab navigator with Swiper Js
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

const Container = styled.div`
  min-height: 0;
  width: 100%;
  height: 100%;

  .swiper {
    height: 100%;
    padding-top: 2.8rem;
  }

  .swiper-pagination {
    top: 0px;
    bottom: unset !important;
  }

  .swiper-pagination-bullet {
    padding: 6.5px 20px;
    border-radius: 100px;
    height: unset;
    width: unset;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.95rem;
    opacity: 1;
    background-color: transparent;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: scroll;
  }

  .swiper-pagination-bullet-active {
    background-color: #ffffff1a;
  }
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
`

type Props = {
  children: [React.ReactNode]
}

const TabNav = ({ children }: Props) => {
  const tabTitles = children.map(child => child.props.title)

  const pagination = {
    clickable: true,
    renderBullet(index, className) {
      return `<span class=${className}>${tabTitles[index] || index}</span>`
    },
  }

  const renderSlides = () =>
    children.map((child, i) => (
      <SwiperSlide key={child?.props?.title || i}>
        <Page>{child}</Page>
      </SwiperSlide>
    ))

  return (
    <Container>
      <Swiper
        pagination={pagination}
        modules={[Pagination]}
        className="mySwiper"
      >
        {renderSlides()}
      </Swiper>
    </Container>
  )
}

export default TabNav
